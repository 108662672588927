// Switch to dark theme / light theme
var switchToLightTheme = document.getElementById('lightThemeButton');
switchToLightTheme.onclick = function () {
	document.body.classList.toggle('light-theme');
	var iconSpan = switchToLightTheme.querySelector('span');
	if (document.body.classList.contains('light-theme')) {
		iconSpan.textContent = 'clear_night';
	} else {
		iconSpan.textContent = 'clear_day';
	}
};

// Show navigation in mobile view
burgerMenu.addEventListener('click', () => {
	header.classList.toggle('navActive');
});
const links = document.querySelectorAll('.link');
links.forEach((link) => {
	link.addEventListener('click', () => {
		header.classList.remove('navActive');
	});
});

// Review carousel
var reviewsArray = [
	{
		id: 'review-1',
		name: 'Isaac Stein',
		content:
			'Mon expérience avec Digital Business a été exceptionnelle. Ils ont compris mes besoins et ont créé un site web qui dépasse mes attentes. Mon entreprise a connu une croissance significative depuis la mise en place du nouveau site. Merci Digital Business!',
		position: 0,
		indicatorActive: true,
	},
	{
		id: 'review-2',
		name: 'Amina Said',
		content:
			'Digital Business a transformé ma vision en réalité avec un site web professionnel et convivial. Leur équipe talentueuse a suivi mes idées tout en apportant leur expertise. Mes clients adorent le nouveau design et la facilité de navigation.',
		position: 100,
		indicatorActive: false,
	},
	{
		id: 'review-3',
		name: 'Nicolas Girard',
		content:
			'Je suis reconnaissant envers Digital Business pour leur excellent travail sur mon site web. Ils ont pris le temps de comprendre mes besoins uniques et ont créé une plateforme qui a considérablement amélioré ma visibilité en ligne. Un partenaire de confiance!',
		position: 200,
		indicatorActive: false,
	},
	{
		id: 'review-4',
		name: 'Emma Deschamps',
		content:
			"Digital Business a été le partenaire idéal dans la création de mon site web. Leur équipe créative a su capturer l'essence de mon entreprise et a créé une plateforme qui attire l'attention de mes clients cibles. Un investissement qui en vaut la peine!",
		position: 300,
		indicatorActive: false,
	},
	{
		id: 'review-5',
		name: 'Marc Dubois',
		content:
			'Travailler avec Digital Business a été une expérience fantastique. Ils ont pris en charge tout le processus, de la conception à la mise en ligne, avec une grande efficacité. Mon entreprise a désormais une présence en ligne forte et professionnelle, merci à Digital Business!',
		position: 400,
		indicatorActive: false,
	},
	{
		id: 'review-6',
		name: 'Fabienne Chantelo',
		content:
			"Digital Business a été incroyablement professionnel et efficace dans la création de mon site web. Grâce à leur expertise, j'ai maintenant une présence en ligne solide, attirant plus de clients et augmentant mes ventes. Je recommande vivement leurs services!",
		position: 500,
		indicatorActive: false,
	},
];
window.onload = function () {
	createCarrousel();
	startRotation();
	resetForm();
};

const carousel = document.getElementById('carousel');
const indicators = document.getElementById('indicators');
var intervalId;

function createCarrousel() {
	for (var i = 0; i < reviewsArray.length; i++) {
		var reviewElement = document.createElement('div');
		reviewElement.style.left = `${reviewsArray[i].position}%`;
		reviewElement.classList.add('review');
		reviewElement.id = reviewsArray[i].id;
		var reviewElementHeader = document.createElement('div');
		reviewElementHeader.classList.add('reviewElementHeader');
		var reviewElementName = document.createElement('p');
		reviewElementName.textContent = reviewsArray[i].name;
		reviewElementName.classList.add('name');
		reviewElementHeader.appendChild(reviewElementName);
		var starContainer = document.createElement('div');
		starContainer.classList.add('starContainer');
		for (var j = 0; j < 5; j++) {
			var starSymbol = document.createElement('i');
			starSymbol.classList.add('material-icons');
			starSymbol.textContent = 'star';
			starContainer.appendChild(starSymbol);
		}
		reviewElementHeader.appendChild(starContainer);
		reviewElement.appendChild(reviewElementHeader);
		var reviewElementContent = document.createElement('p');
		reviewElementContent.textContent = reviewsArray[i].content;
		reviewElementContent.classList.add('content');
		reviewElement.appendChild(reviewElementContent);
		carousel.appendChild(reviewElement);
		var indicatorElement = document.createElement('div');
		indicatorElement.id = reviewsArray[i].id + '-indicator';
		indicatorElement.classList.add('indicator');
		if (i === 0) indicatorElement.classList.add('activeIndicator');
		indicators.appendChild(indicatorElement);
	}
}

function adjustpositionProperties(index) {
	reviewsArray[index].position = 0;
	let j = 100;
	if (index < reviewsArray.length)
		for (var i = index + 1; i < reviewsArray.length; i++) {
			reviewsArray[i].position = j;
			j += 100;
		}

	j = -100;
	if (index > 0)
		for (var i = index - 1; i >= 0; i--) {
			reviewsArray[i].position = j;
			j -= 100;
		}

	for (var i = 0; i < reviewsArray.length; i++) {
		if (reviewsArray[i].indicatorActive === true) {
			reviewsArray[i].indicatorActive = false;
			var indicatorElement = document.getElementById(reviewsArray[i].id + '-indicator');
			indicatorElement.classList.remove('activeIndicator');
		}
	}
	reviewsArray[index].indicatorActive = true;
	var indicatorElement2 = document.getElementById(reviewsArray[index].id + '-indicator');
	indicatorElement2.classList.add('activeIndicator');

	for (var i = 0; i < reviewsArray.length; i++) {
		var imgElement = document.getElementById(reviewsArray[i].id);
		imgElement.style.left = `${reviewsArray[i].position}%`;
	}
}

function startRotation() {
	intervalId = setInterval(() => {
		if (reviewsArray[reviewsArray.length - 1].position === 0) {
			let j = 0;
			for (var i = 0; i < reviewsArray.length; i++) {
				reviewsArray[i].position = j;
				j += 100;
			}
		} else {
			for (var i = 0; i < reviewsArray.length; i++) {
				reviewsArray[i].position -= 100;
			}
		}
		for (var i = 0; i < reviewsArray.length; i++) {
			if (reviewsArray[i].indicatorActive === true) {
				reviewsArray[i].indicatorActive === false;
				var indicatorElement = document.getElementById(reviewsArray[i].id + '-indicator');
				indicatorElement.classList.remove('activeIndicator');
			}
			if (reviewsArray[i].position === 0) {
				reviewsArray[i].indicatorActive = true;
				var indicatorElement = document.getElementById(reviewsArray[i].id + '-indicator');
				indicatorElement.classList.add('activeIndicator');
			}
		}
		for (var i = 0; i < reviewsArray.length; i++) {
			var imgElement = document.getElementById(reviewsArray[i].id);
			imgElement.style.left = `${reviewsArray[i].position}%`;
		}
	}, 8000);
}

function stopRotation() {
	clearInterval(intervalId);
}

indicators.addEventListener('click', (event) => {
	if (event.target.classList.contains('indicator')) {
		stopRotation();
		let index = event.target.id.split('-')[1];
		adjustpositionProperties(Number(index) - 1);
		startRotation();
	}
});

// Send mail reset inputs
function resetForm() {
	document.getElementById('sendMailName').value = '';
	document.getElementById('sendMailEmail').value = '';
	document.getElementById('sendMailSubject').value = '';
	document.getElementById('sendMailPhoneNumber').value = '';
	document.getElementById('sendMailMessage').value = '';
}
